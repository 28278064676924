import React, {
  memo,
  useRef,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import isPolygonSelfIntersecting from "js/algorithms/geometry/isPolygonSelfIntersecting";
import {useGoogleMap} from "js/context/GoogleMapContext";
import {voidFunc} from "js/constants/PropTypeUtils";
import {getCoords} from "@turf/turf";

export const mapPolygonFromBboxPolygon = (poly, props) => {
  return poly && <MapPolygon coordinates={getCoords(poly)[0].map((arr) => ({lng: arr[0], lat: arr[1]}))} {...props}/>;
};

const MapPolygon = (props: MapPolygon.propTypes) => {

  const googleMap = useGoogleMap();
  const mapRef = useRef(googleMap);
  const polygon = useRef(null);

  const listeners = useRef({
    moveListener: null,
    clickListener: null,
    insertListener: null,
    removeListener: null,
    mouseOutListener: null,
    mouseOverListener: null,
    polygonClickListener: null,
    polygonReleaseListener: null,
  });

  const getPolygonPath = useCallback(() => {
    const polygonpath = [];

    for (let i = 0; i < polygon.current.getPath().getLength(); i++) {
      polygonpath.push(polygon.current.getPath().getAt(i));
    }

    return polygonpath;
  }, []);

  const checkIfIntersecting = useCallback(() => {
    const polygonpath = getPolygonPath();
    const intersect = isPolygonSelfIntersecting(polygonpath);

    props.setIsPolygonValid(!intersect, props.pindex);
  }, [props.setIsPolygonValid, props.pindex]);

  const onPolygonClick = useCallback((event) => {
    if (props.isMergingPolygon) {
      polygon.current.setOptions({
        fillColor: 'blue'
      });
      props.setPolygonMerge(props.pindex);
    }

    if (event.vertex !== undefined) {
      const coords = polygon.current.getPath().getAt(event.vertex);
      const marker = {
        position: new google.maps.LatLng(coords.lat(), coords.lng())
      };
      props.setSelectedMarker(marker);
    }
  }, [props.isMergingPolygon, props.setPolygonMerge, props.pindex, props.setSelectedMarker]);

  const onPolygonRelease = useCallback(() => {

  }, []);

  const handleOnClick = useCallback((event) => {
    if (!props.disabled) {
      props.onClick(event);
    }
  }, [props.onClick, props.disabled]);

  const handleOnMouseEnter = useCallback(() => {
    if (!props.disabled) {
      props.onMouseEnter();
    }
  }, [props.onMouseEnter, props.disabled]);

  const handleOnMouseLeave = useCallback(() => {
    if (!props.disabled) {
      props.onMouseLeave();
    }
  }, [props.onMouseLeave, props.disabled]);

  const handleOnMove = (index, point) => {
    props.onMove(index, point, getPolygonPath(), props.pindex);
  };

  const handleOnInsert = (index, point) => {
    props.onInsert(index, getPolygonPath(), props.pindex);
  };

  useEffect(() => {
    let options = {
      paths: props.coordinates,
      strokeColor: props.strokeColor,
      strokeOpacity: 0.8,
      strokeWeight: props.strokeWeight || 2,
      fillColor: props.isValid ? props.fillColor : 'red',
      fillOpacity: props.fillOpacity || 0.0,
      editable: props.editable || false,
      suppressUndo: true,
      map: mapRef.current,
      zIndex: props.zIndex || null,
      clickable: !props.disabled,
    };

    if (!polygon.current) {
      polygon.current = new google.maps.Polygon(options);
    }
    else {
      polygon.current.setOptions(options);
    }

  }, [
    checkIfIntersecting, polygon.current, props.coordinates, props.strokeColor, props.strokeWeight, props.fillColor,
    props.fillOpacity, props.editable, props.zIndex, props.isValid, props.disabled
  ]);

  useEffect(() => {
    if (props.editable) {
      /* DrawingManager Polygon */
      google.maps.event.removeListener(listeners.current.moveListener);
      listeners.current.moveListener = google.maps.event.addListener(polygon.current.getPath(), "set_at", handleOnMove);

      google.maps.event.removeListener(listeners.current.insertListener);
      listeners.current.insertListener = google.maps.event.addListener(polygon.current.getPath(), "insert_at", handleOnInsert);

      google.maps.event.removeListener(listeners.current.polygonClickListener);
      listeners.current.polygonClickListener = polygon.current.addListener("mousedown", onPolygonClick);

      google.maps.event.removeListener(listeners.current.polygonReleaseListener);
      listeners.current.polygonReleaseListener = polygon.current.addListener("mouseup", onPolygonRelease);

      checkIfIntersecting();
    }
    else {
      /* Standard Polygon */
      if (!props.disabled) {
        google.maps.event.removeListener(listeners.current.clickListener);
        listeners.current.clickListener = polygon.current.addListener('click', handleOnClick);

        google.maps.event.removeListener(listeners.current.mouseOverListener);
        listeners.current.mouseOverListener = polygon.current.addListener('mouseover', handleOnMouseEnter);

        google.maps.event.removeListener(listeners.current.mouseOutListener);
        listeners.current.mouseOutListener = polygon.current.addListener('mouseout', handleOnMouseLeave);
      }
    }
  }, [props.editable, props.disabled, checkIfIntersecting, handleOnClick, handleOnMouseEnter, handleOnMouseLeave, handleOnMove, handleOnInsert, onPolygonClick, onPolygonRelease]);

  useEffect(() => {
    return () => {
      if (polygon.current) {
        polygon.current.setMap(null);
        polygon.current = null;

        Object.values(listeners.current).forEach((listener) => {
          google.maps.event.removeListener(listener);
        });
      }
    };
  }, []);

  return props.children || null;
};

MapPolygon.propTypes = {
  children: PropTypes.any,
  coordinates: PropTypes.arrayOf(PropTypes.object).isRequired,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  disabled: PropTypes.bool,
  strokeWeight: PropTypes.number,
  fillOpacity: PropTypes.number,
  pindex: PropTypes.number,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  selected: PropTypes.bool,
  editable: PropTypes.bool,
  onMove: PropTypes.func,
  onInsert: PropTypes.func,
  setSelectedMarker: PropTypes.func,
  setPolygonMerge: PropTypes.func,
  zIndex: PropTypes.number,
  isValid: PropTypes.bool,
  isMergingPolygon: PropTypes.bool,
  setIsPolygonValid: PropTypes.func
};

MapPolygon.defaultProps = {
  onClick: voidFunc,
  onMouseEnter: voidFunc,
  onMouseLeave: voidFunc,
  disabled: false,
  isValid: true,
};

/** @component */
export default memo(MapPolygon);