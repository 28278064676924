'use strict';

import React, { memo } from 'react';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useLangFile } from 'js/context/LanguageContext';
import { usePrescriptionJob } from './PrescriptionJobContext';
import { PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import { connect } from 'react-redux';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    padding: 8,
    borderBottom: `1px solid ${grey[200]}`,
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    flex: 1,
  },
  label: {
    fontSize: '16px',
    padding: [[0, 8]],
    whiteSpace: 'nowrap',
  },
});

const mapStateToProps = (store) => {
  return {
    field: store.field.selectedField,
  };
};

const PrescriptionSummary = ({ classes, field }) => {
  const LangFile = useLangFile();
  const { prescriptionJob } = usePrescriptionJob();
  const { fieldSize, totalPrescription, maxPrescription, unit } = prescriptionJob;

  const unitToPrescriptionTextValues = {
    [PRESCRIPTION_UNIT.LITER]: {
      totalPrescriptionText: `${Number(totalPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.liters.short}`,
      maxPrescriptionText: `${Number(maxPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.liters.short}`,
    },
    [PRESCRIPTION_UNIT.PIECES_M2]: {
      totalPrescriptionText: `${Number(totalPrescription / 1000).toFixed()} t.${LangFile.PrescriptionSettings.unit.pieces.short}`,
      maxPrescriptionText: `${Number(maxPrescription / 1000).toFixed(2)} ${LangFile.PrescriptionSettings.unit.liters.short}`,
    },
    [PRESCRIPTION_UNIT.KILOGRAM]: {
      totalPrescriptionText: `${Number(totalPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.kilogram.short}`,
      maxPrescriptionText: `${Number(maxPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.kilogram.short}`,
    },
  };

  const { totalPrescriptionText, maxPrescriptionText } = unitToPrescriptionTextValues[unit];
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.items}>
        <div className={classes.item}>
          <span
            className={
              classes.label
            }>{`${LangFile.PrescriptionTableHead.field}: ${field?.name === '' ? field?.mef : field?.name}`}</span>
          <span>-</span>
          <span className={classes.label}>
            {LangFile.PrescriptionSummary.arableLand}: {Number(fieldSize.toFixed(2))} ha
          </span>
        </div>

        <div className={classes.item}>
          <span className={classes.label}>{LangFile.PrescriptionSummary.totalAmount}:</span>
          <span className={classes.label}>{totalPrescriptionText}</span>
        </div>

        <div className={classes.item}>
          <span className={classes.label}>{LangFile.PrescriptionSummary.allocationMax}:</span>
          <span className={classes.label}>{maxPrescriptionText}</span>
        </div>
      </Paper>
    </div>
  );
};

export default memo(connect(mapStateToProps)(withStyles(styles)(PrescriptionSummary)));
