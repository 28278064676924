// @flow

import React, { memo } from 'react';
import { useLangFile } from 'js/context/LanguageContext';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';

const PrescriptionAdjustButton = (props: PrescriptionAdjustButton.propTypes) => {
  const LangFile = useLangFile();
  const { prescriptionJob } = usePrescriptionJob();

  return (
    <Box ml={1}>
      <Button variant={'contained'} color={'secondary'} onClick={props.onClick}>
        <EqualizerOutlinedIcon />
        <Box ml={1}>{LangFile.PrescriptionAdjustButton.label}</Box>
      </Button>
    </Box>
  );
};

PrescriptionAdjustButton.propTypes = {
  onClick: PropTypes.func,
};

export default memo(PrescriptionAdjustButton);
