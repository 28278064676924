import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DataLayer from '../../DataLayer/DataLayer';
import FeatureDOM from '../../DataLayer/FeatureDOM';
import { Box } from '@material-ui/core';
import { getLayerConfig } from '../../Prescription/PrescriptionUtils';
import { useLangFile, useLangFileRef } from '../../../context/LanguageContext';
import { renderStandardDemandLegend } from '../../DemandScale/DemandScale';
import useMapEvent from '../../DataLayer/hooks/useMapEvent';
import { useGoogleMap } from '../../../context/GoogleMapContext';
import MapCanvas from './MapCanvas';
import * as turf from '@turf/turf';
import { voidFunc } from '../../../constants/PropTypeUtils';
import ViewModeConstants from '../../../constants/ViewModeConstants';
import {
  ClassificationColoringStrategy,
  getColoringStrategy,
} from '../../../model/surveys/SurveyColoringStrategies';
import { SATELLITE_LAYERS } from '../../../constants/SatelliteLayers';
import { SURVEY_LAYERS } from '../../../constants/SurveyLayers';
import { truncateNumber } from '../../../helpers/NumberUtils';
import useEvent from '../../../hooks/useEvent';

export const getTooltip = (
  layer,
  allValues,
  target,
  classificationsEnabled,
  variationsEnabled,
  LangFile
) => {
  const decimals = 2;
  const values = allValues[layer];
  const config = getLayerConfig(layer);
  const coloringStrategy = getColoringStrategy(
    layer,
    ViewModeConstants.ANALYSIS,
    classificationsEnabled,
    variationsEnabled,
    values
  );
  const classifier = new ClassificationColoringStrategy(layer, ViewModeConstants.ANALYSIS)
    .classifier;

  let text = `${config.getName(LangFile)}`;
  let short = config.getShorthand(LangFile);
  if (short) {
    text += ` (${short})`;
  }
  text += ':';

  let result = { layer: layer, value: null, text: text, icon: null, unit: config.unit };

  if (values && target && target.x >= 0 && target.y >= 0) {
    let { x, y } = target;
    let col = values[y];
    let row = col && col[x];
    let val = row && truncateNumber(row, decimals);

    if (val != null) {
      let icon = null;
      if (classificationsEnabled) {
        let classification = classifier(val);
        if (classification) {
          icon = renderStandardDemandLegend(classification, LangFile, layer);
        }
      }

      if (layer === SURVEY_LAYERS.FI_SOIL_CLASS) {
        result.value = coloringStrategy.getLabel(val);
      } else {
        result.value = val;
      }

      result.icon = icon;
    }
  }

  return result;
};

const MapCanvasTooltip = (props: MapCanvasTooltip.propTypes) => {
  const LangFile = useLangFile();
  const LangFileRef = useLangFileRef();
  const googleMap = useGoogleMap();
  const contextRef = useRef(null);

  const [target, setTarget] = useState(null);
  const [showDebug, setShowDebug] = useState(false);

  const items =
    props.layers
      .map((layer, idx, arr) => {
        // Don't show tooltip for VISIBLE (NDVI) layer.
        // Don't show tooltip for VARIATIONS if the VITALITY is also shown.
        if (
          layer === SATELLITE_LAYERS.VISIBLE ||
          (layer === SATELLITE_LAYERS.VARIATIONS_NDVI &&
            arr.indexOf(SATELLITE_LAYERS.VITALITY_NDVI) !== -1)
        ) {
          return null;
        }
        return getTooltip(
          layer,
          props.values,
          target,
          props.classificationsEnabled,
          props.variationsEnabled,
          LangFile
        );
      })
      .filter(Boolean)
      .filter((item) => item.value !== undefined && item.value !== null) || [];

  const handleDebugDown = useCallback((event) => {
    if (!event) {
      return;
    }

    if (event.altKey) {
      setShowDebug((val) => !val);
    }
  }, []);

  const handleClick = useCallback(
    (event) => {
      if (!event || !showDebug || !target) {
        return;
      }

      let lines = [];
      let headers = [
        'lat',
        'lng',
        ...items.map((item) => LangFileRef.current.SurveyLayers[item.layer].shorthand),
      ].join(',');

      lines.push(headers);
      lines.push([target.lat, target.lng, ...items.map((item) => item.value)].join(','));

      window.prompt('Copy', lines.join('\n'));
    },
    [showDebug, items, target]
  );

  useEvent('keydown', handleDebugDown);
  useEvent('click', handleClick);

  // Handles setting the reference to the context on which to draw the hover effect.
  const onHoverCanvasDraw = useCallback((ctx) => {
    if (!contextRef.current) {
      contextRef.current = ctx;
    }
  }, []);

  // Handles mapping from map to canvas space.
  const latLngToPixel = useCallback(
    (lat, lng, allowOutOfBounds) => {
      const bounds = props.selectedField.bounds;
      const { width, height } = props.canvasSize;

      // Figure out where in the image we are clicking (0 to 1)
      const { north, south, east, west } = bounds;

      const latRange = north - south;
      const lngRange = east - west;
      const lngPartial = (lng - west) / lngRange;
      const latPartial = (north - lat) / latRange;

      // Convert this to the x and y coordinates in the canvas
      let x = Math.round(width * lngPartial);
      let y = Math.round(height * latPartial);

      if (!allowOutOfBounds) {
        x = Math.min(width - 1, x);
        x = Math.max(0, x);
        y = Math.min(height - 1, y);
        y = Math.max(0, y);
      }

      return { x, y };
    },
    [props.selectedField, props.canvasSize]
  );

  // Event handler for "mousemove" events on the map that is translated into the canvas hover effect.
  const mousemoveHandler = useCallback(
    (event) => {
      let ctx = contextRef.current;

      if (!ctx) {
        return;
      }

      let s = props.resolutionScale;
      let width = props.canvasSize.width * s;
      let height = props.canvasSize.height * s;
      let prevFill = ctx.fillStyle;
      ctx.clearRect(0, 0, width, height);

      let latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      let point = turf.point([latLng.lng, latLng.lat]);
      let { x, y } = latLngToPixel(latLng.lat, latLng.lng, true);

      let borderPoints = props.selectedField.polygon.coordinates[0];
      let border = turf.polygon([borderPoints]);

      if (turf.booleanContains(border, point)) {
        let t = { ...latLng, x, y };

        setTarget(t);
        props.onTargetChanged(t);

        ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
        ctx.fillRect(x * s, y * s, s, s);
        ctx.fillStyle = prevFill;
      } else {
        setTarget(null);
        props.onTargetChanged(null);
      }
    },
    [props.selectedField, props.resolutionScale, props.onTargetChanged]
  );

  // Register mousemove event handler on the map
  useMapEvent(googleMap, 'mousemove', mousemoveHandler);

  return (
    <>
      <MapCanvas
        onDraw={onHoverCanvasDraw}
        zIndex={3}
        bounds={props.selectedField.bounds}
        width={props.canvasSize.width * props.resolutionScale}
        height={props.canvasSize.height * props.resolutionScale}
      />

      <DataLayer>
        {target && items.length > 0 && (
          <FeatureDOM style={{ pointerEvents: 'none' }} lat={target.lat} lng={target.lng}>
            <Box position={'relative'} style={{ userSelect: 'none', pointerEvents: 'none' }}>
              <Box position={'absolute'} style={{ transform: 'translate(24px, -24px)' }}>
                <Box
                  py={0.5}
                  px={1}
                  display={'flex'}
                  flexDirection={'column'}
                  color={'white'}
                  bgcolor={'black'}
                  flexWrap={'nowrap'}
                  style={{ opacity: 0.7 }}
                  borderRadius={4}>
                  {showDebug && (
                    <Box
                      py={0.5}
                      display={'inline-flex'}
                      flexWrap={'nowrap'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      key={'point-coordinates'}>
                      <Box style={{ whiteSpace: 'nowrap' }}>Lat/Lng:</Box>
                      <Box
                        pl={1}
                        style={{ whiteSpace: 'nowrap' }}
                        height={'100%'}
                        alignItems={'center'}
                        display={'flex'}>
                        {Number(target.lat).toFixed(4)}, {Number(target.lng).toFixed(4)}
                      </Box>
                    </Box>
                  )}

                  {items.map(({ layer, value, text, icon, unit }) => {
                    let showIcon = props.classificationsEnabled && Boolean(icon);
                    let classIcon = showIcon && icon;

                    return (
                      <Box
                        py={0.5}
                        display={'inline-flex'}
                        flexWrap={'nowrap'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        key={layer}>
                        <Box style={{ whiteSpace: 'nowrap' }}>{text}</Box>
                        <Box
                          pl={1}
                          style={{ whiteSpace: 'nowrap' }}
                          height={'100%'}
                          alignItems={'center'}
                          display={'flex'}>
                          {value}
                          {unit}
                          <span style={{ paddingLeft: 4 }} />
                          {classIcon}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </FeatureDOM>
        )}
      </DataLayer>
    </>
  );
};

MapCanvasTooltip.propTypes = {
  layers: PropTypes.array,
  values: PropTypes.object,
  selectedField: PropTypes.object,
  canvasSize: PropTypes.object,
  classificationsEnabled: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  resolutionScale: PropTypes.number,
  onTargetChanged: PropTypes.func,
};

MapCanvasTooltip.defaultProps = {
  onTargetChanged: voidFunc,
};

export default memo(MapCanvasTooltip);
