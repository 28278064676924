// @flow

import AppBar from '@material-ui/core/AppBar';
import style from 'js/components/Prescription/PrescriptionEditor.module.less';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  ArrowBack,
  Block,
  Brush,
  CallSplit,
  Clear,
  CloudDownload,
  Delete,
  Edit,
  MergeType,
  Settings,
  InfoOutlined,
  Filter2,
  Filter3,
  ViewColumnOutlined,
  Remove,
} from '@material-ui/icons';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CompareIcon from '@material-ui/icons/Compare';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { PRESCRIPTION_OVERRIDES_TOOL, PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import TextField from '@material-ui/core/TextField';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React, { Fragment, memo, useCallback, useState } from 'react';
import { useLangFile } from 'js/context/LanguageContext';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import PropTypes from 'prop-types';
import PrescriptionAdjustButton from 'js/components/Prescription/Dialogs/PrescriptionAutoAdjustDialog/PrescriptionAdjustButton';
import ManualFeatureConstants from '../../../constants/ManualFeatureConstants';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../../hooks/useFirebaseAnalytics';
import { useHasFeatures } from '../../../context/PermissionContext';
import FeatureConstants from '../../../constants/FeatureConstants';
import PolygonBufferIcon from '../../Icons/PolygonBufferIcon';
import { Divider, Typography } from '@material-ui/core';
import MultipleActionsDialog from '../../Prescription/Dialogs/MultipleActionsDialog.jsx';
import { MetaTypes } from '../PrescriptionJob';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const PrescriptionToolbar = (props: PrescriptionToolbar.propTypes) => {
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const allowBufferZones = useHasFeatures([FeatureConstants.PRESCRIPTION_BUFFER_ZONE]);

  const { prescriptionJob } = usePrescriptionJob();

  const [showDownloadConfirm, setShowDownloadConfirm] = useState(false);

  let numberOfOverrides = 0;
  if (prescriptionJob.overrideAreas) {
    numberOfOverrides = Object.keys(prescriptionJob.overrideAreas).length;
  }

  let adjustmentRequired = false;
  if (prescriptionJob.intervals.length > 1) {
    const difference =
      Number(prescriptionJob.totalPrescription) > Number(prescriptionJob.maxPrescription)
        ? Number(prescriptionJob.totalPrescription) - Number(prescriptionJob.maxPrescription)
        : Number(prescriptionJob.maxPrescription) - Number(prescriptionJob.totalPrescription);
    adjustmentRequired = !(difference < 0.01 && difference > -0.01);
  }

  const onKeyUp = useCallback((event) => {
    if (typeof event.persist === 'function') {
      event.persist();
    }

    if (event.shiftKey) {
      return;
    }

    if (event.key === 'Enter') {
      event.target.blur();
    }
  }, []);

  const selectOnFocus = useCallback((event) => {
    const input = event.target;
    input.setSelectionRange(0, input.value.length);
  }, []);

  const onDownload = useCallback((shouldDownloadMultiple: boolean) => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_DOWNLOAD);
    setShowDownloadConfirm(false);
    props.onDownloadJob(shouldDownloadMultiple);
  });

  const handleDownloadClick = () => {
    if (props.hasMultipleJobs) {
      setShowDownloadConfirm(true);
      return;
    }

    onDownload(false);
  };

  const onAddView = useCallback((event) => {
    props.onAddView();
  });

  const onRemoveView = useCallback((event) => {
    props.onRemoveView();
  });

  const onAutoAdjustClicked = useCallback(() => {
    props.onSetShowAutoAdjustDialog(true);
  }, []);

  const isLimeJob = prescriptionJob.metaType === MetaTypes.LIME;

  const useHideLabels = (isLimeJob, adjustmentRequired) => {
    const breakpoints = {
      settings: { lime: 1170, adjust: 970, default: 780 },
      download: { lime: 1100, adjust: 900, default: 710 },
      save: { lime: 1030, adjust: 840, default: 650 },
      future: 860,
      limeModel: 930,
    };

    const getBreakpoint = (key) =>
      breakpoints[key]?.[isLimeJob ? 'lime' : adjustmentRequired ? 'adjust' : 'default'];

    return {
      settings: useMediaQuery(`(max-width: ${getBreakpoint('settings')}px)`),
      download: useMediaQuery(`(max-width: ${getBreakpoint('download')}px)`),
      save: useMediaQuery(`(max-width: ${getBreakpoint('save')}px)`),
      future: useMediaQuery(`(max-width: ${breakpoints.future}px)`),
      limeModel: useMediaQuery(`(max-width: ${breakpoints.limeModel}px)`),
    };
  };

  const hideLabels = useHideLabels(isLimeJob, adjustmentRequired);

  let unitString;
  switch (prescriptionJob.unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = `${LangFile.PrescriptionSettings.unit.liters.short} / ha`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `${LangFile.PrescriptionSettings.unit.pieces.short} / m^2`;
      break;
    default:
      unitString = `${LangFile.PrescriptionSettings.unit.kilogram.short} / ha`;
      break;
  }

  return (
    <AppBar position="static" color="inherit" className={style.AppBar} elevation={0}>
      <Toolbar className={style.Toolbar}>
        {(props.splitting || props.merging || props.overriding) && (
          <Box className={style.ButtonWrapper}>
            <Tooltip title={LangFile.PrescriptionToolbar.backToZones}>
              <IconButton color="inherit" onClick={props.onReturnToEditor}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {!(props.splitting || props.merging || props.overriding) && (
          <Fragment>
            <Box className={style.ButtonWrapper}>
              <Tooltip title={LangFile.PrescriptionToolbar.exit}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.exit}
                  onClick={props.onExitSafe}>
                  <Clear />
                </IconButton>
              </Tooltip>
              <Tooltip title={LangFile.PrescriptionToolbar.settingsTooltip}>
                <Box mr={1} ml={1}>
                  <Button
                    variant="outlined"
                    aria-label={LangFile.PrescriptionToolbar.settingsTooltip}
                    onClick={props.onShowSettings}>
                    <Settings />
                    <Box sx={{ display: hideLabels.settings ? 'none' : 'flex' }} pl={1}>
                      {LangFile.PrescriptionToolbar.settings}
                    </Box>
                  </Button>
                </Box>
              </Tooltip>
              {isLimeJob && (
                <Tooltip title={LangFile.PrescriptionToolbar.limeModel.tooltip}>
                  <Box mr={1} ml={1}>
                    <a
                      href={
                        'https://cropline-assets.s3.eu-central-1.amazonaws.com/Cropline+kalkmodel.pdf'
                      }
                      download="LimeModel">
                      <Button variant={'outlined'}>
                        <InfoOutlined />
                        <Box sx={{ display: hideLabels.limeModel ? 'none' : 'flex' }} ml={1}>
                          {LangFile.PrescriptionToolbar.limeModel.button}
                        </Box>
                      </Button>
                    </a>
                  </Box>
                </Tooltip>
              )}
              <Tooltip title={LangFile.PrescriptionToolbar.downloadTooltip}>
                <Box mr={1} ml={1}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    aria-label={LangFile.PrescriptionToolbar.downloadTooltip}
                    onClick={handleDownloadClick}>
                    <CloudDownload />
                    <Box sx={{ display: hideLabels.download ? 'none' : 'flex' }} pl={1}>
                      {LangFile.PrescriptionToolbar.download}
                    </Box>
                  </Button>
                </Box>
              </Tooltip>

              <MultipleActionsDialog
                open={showDownloadConfirm}
                title={LangFile.PrescriptionSplitViewContainer.multipleJobs.downloadDialog.title}
                message={
                  LangFile.PrescriptionSplitViewContainer.multipleJobs.downloadDialog.message
                }
                primaryText={
                  LangFile.PrescriptionSplitViewContainer.multipleJobs.downloadDialog.primary
                }
                onPrimary={() => onDownload(true)}
                secondaryText={
                  LangFile.PrescriptionSplitViewContainer.multipleJobs.downloadDialog.secondary
                }
                onSecondary={() => onDownload(false)}
                onCancel={() => setShowDownloadConfirm(false)}
                cancelText={
                  LangFile.PrescriptionSplitViewContainer.multipleJobs.downloadDialog.cancel
                }
              />

              <Tooltip title={LangFile.PrescriptionToolbar.savePrescription.tooltip}>
                <Box mr={1} ml={1}>
                  <Button variant={'contained'} color={'primary'} onClick={props.onSaveJob}>
                    <SaveOutlinedIcon />
                    <Box sx={{ display: hideLabels.save ? 'none' : 'flex' }} ml={1}>
                      {LangFile.PrescriptionToolbar.savePrescription.button}
                    </Box>
                  </Button>
                </Box>
              </Tooltip>

              {!props.overriding && !isLimeJob && adjustmentRequired && (
                <PrescriptionAdjustButton onClick={onAutoAdjustClicked} />
              )}
              {props.hasMultipleJobs && (
                <Tooltip title={LangFile.PrescriptionToolbar.handleFuturePrescription.tooltip}>
                  <Box mr={1} ml={1}>
                    <Button variant={'outlined'} onClick={props.onHandleFutureJobClick}>
                      <CompareIcon />
                      <Box sx={{ display: hideLabels.future ? 'none' : 'flex' }} ml={1}>
                        {LangFile.PrescriptionToolbar.handleFuturePrescription.button}
                      </Box>
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Box>

            <Box>
              {props.numberOfAddedViews > 0 ? (
                <Tooltip title={LangFile.PrescriptionToolbar.removeAddedView}>
                  <IconButton
                    color="inherit"
                    aria-label={LangFile.PrescriptionToolbar.removeAddedView}
                    onClick={onRemoveView}>
                    <Filter2 />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={LangFile.PrescriptionToolbar.addNewView}>
                  <IconButton
                    color="inherit"
                    aria-label={LangFile.PrescriptionToolbar.addNewView}
                    onClick={onAddView}>
                    <Filter3 />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={LangFile.PrescriptionToolbar.splitIntervals}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.splitIntervals}
                  disabled={!props.splittingEnable}
                  onClick={props.onInitiateSplit}>
                  <CallSplit />
                </IconButton>
              </Tooltip>

              <Tooltip title={LangFile.PrescriptionToolbar.mergeIntervals}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.mergeIntervals}
                  disabled={!props.splittingEnable || prescriptionJob.intervals.length < 2}
                  onClick={props.onInitiateMerge}>
                  <MergeType />
                </IconButton>
              </Tooltip>

              <Tooltip title={LangFile.PrescriptionToolbar.overrideModeTooltip}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.overrideModeTooltip}
                  onClick={props.onToggleIsOverriding}>
                  <Badge badgeContent={numberOfOverrides} color={'secondary'} overlap={'circle'}>
                    <Edit />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Box>
          </Fragment>
        )}

        {props.overriding && (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {(allowBufferZones || ManualFeatureConstants.PRESCRIPTION_BUFFER_ZONE) && (
              <Box minWidth={100} mt={'-4px'} className={style.TextFieldBox}>
                <Box px={3} display={'flex'}>
                  <Divider orientation={'vertical'} flexItem />
                </Box>

                <Button
                  variant={'outlined'}
                  onClick={props.onEditBuffer}
                  startIcon={<PolygonBufferIcon />}
                  className={style.Button}>
                  {LangFile.PrescriptionToolbar.buffer}
                </Button>

                <Box px={3} display={'flex'}>
                  <Divider orientation={'vertical'} flexItem />
                </Box>
              </Box>
            )}

            <ToggleButtonGroup
              size="small"
              value={props.tool}
              exclusive
              onChange={props.onToolChanged}>
              <ToggleButton value={PRESCRIPTION_OVERRIDES_TOOL.OVERRIDE}>
                <Brush />
              </ToggleButton>
              <ToggleButton value={PRESCRIPTION_OVERRIDES_TOOL.EXCEPTION}>
                <Block />
              </ToggleButton>
              <ToggleButton value={PRESCRIPTION_OVERRIDES_TOOL.ERASER}>
                <Delete />
              </ToggleButton>
            </ToggleButtonGroup>

            {props.tool === PRESCRIPTION_OVERRIDES_TOOL.OVERRIDE && (
              <Box width={100} mt={'-4px'} ml={1} className={style.TextFieldBox}>
                <TextField
                  value={props.overrideBrushValue}
                  InputProps={{
                    endAdornment: unitString,
                  }}
                  onChange={props.onOverrideBrushValueChanged}
                  inputProps={{
                    onFocus: selectOnFocus,
                    onKeyUp: onKeyUp,
                  }}
                  type={'tel'}
                  margin="dense"
                  variant={'outlined'}
                  label={LangFile.PrescriptionToolbar.value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            )}

            <Box width={100} mt={'-4px'} ml={1} className={style.TextFieldBox}>
              <TextField
                value={props.overrideBrushSize}
                InputProps={{
                  endAdornment: 'm',
                }}
                inputProps={{
                  onFocus: selectOnFocus,
                  onKeyUp: onKeyUp,
                  onBlur: props.onOverrideBrushSizeBlur,
                }}
                onChange={props.onOverrideBrushSizeChanged}
                type={'tel'}
                margin="dense"
                variant={'outlined'}
                label={LangFile.PrescriptionToolbar.size}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

PrescriptionToolbar.propTypes = {
  tool: PropTypes.string,
  merging: PropTypes.bool,
  splitting: PropTypes.bool,
  mergingEnable: PropTypes.bool,
  splittingEnable: PropTypes.bool,
  overriding: PropTypes.bool,
  overrideBrushSize: PropTypes.number,
  overrideBrushValue: PropTypes.number,
  hasMultipleJobs: PropTypes.bool,

  onEditBuffer: PropTypes.any,
  onExitSafe: PropTypes.any,
  onDownloadJob: PropTypes.func,
  onToolChanged: PropTypes.func,
  onShowSettings: PropTypes.func,
  onInitiateSplit: PropTypes.func,
  onInitiateMerge: PropTypes.func,
  onReturnToEditor: PropTypes.func,
  onToggleIsOverriding: PropTypes.func,
  onOverrideBrushSizeBlur: PropTypes.func,
  onOverrideBrushSizeChanged: PropTypes.func,
  onOverrideBrushValueChanged: PropTypes.func,
  onSetShowAutoAdjustDialog: PropTypes.func,
  onHandleFutureJobClick: PropTypes.func,
  onSaveJob: PropTypes.func,
  numberOfAddedViews: PropTypes.number,
  onAddView: PropTypes.func,
  onRemoveView: PropTypes.func,
};

export default memo(PrescriptionToolbar);
