// @flow
import { matrix, map, pow } from 'mathjs';

// CONSTANTS

// Constants used in calculating optimal reaction number
export const OPTIMAL_REACTION_NUMBER_CONSTS = {
  a1: 6.214646884,
  a2: -0.106353847,
  a3: 0.05849669364,
  a4: -0.000321869731,
  a5: -0.0001956640444,
  a6: 0.0002259942764,
  a7: 0.0000108952688563956,
  a8: -0.0000420025606745461,
  a9: 0.0000188638038937997,
  a10: -0.000010512229419397,
};

// Constants used in calculating the amnount of lime to raise the reaction numbers by 0.1
export const LIME_AMOUNT_TO_RAISE_RT_CONSTS = {
  a1: 0.3070760541,
  a2: 0.0673773926,
  a3: 0.01589789336,
  a4: 0.002691558658,
  a5: -0.0006998658884,
  a6: 0.0000619205761440944,
  a7: -0.0001963978573,
  a8: 0.0000633188001691757,
  a9: -0.00000629182813964212,
  a10: 0.0000119244514714228,
};

// Operation to calculate optimal reaction numbers for a given field based on humus- and clayvalues.
export const calculateOptimalReactionNumberMatrix = (
  humusValues: Array<Array<number>>,
  clayValues: Array<Array<number>>
): matrix => {
  const humusMatrix = matrix(humusValues);
  const clayMatrix = matrix(clayValues);

  const z1Matrix = map(humusMatrix, (value, index) =>
    calculateOptimalReactionNumber(value, clayMatrix.get(index))
  );

  return z1Matrix;
};

// Function to calculate optimal reaction number for a single point on a field based on humus- and clayvalues.
export const calculateOptimalReactionNumber = (
  humusValue: number | null,
  clayValue: number | null
): number => {
  if (humusValue === null && clayValue === null) {
    return null;
  }

  const x = humusValue !== null ? humusValue : 0;
  const y = clayValue !== null ? clayValue : 0;

  const { a1, a2, a3, a4, a5, a6, a7, a8, a9, a10 } = OPTIMAL_REACTION_NUMBER_CONSTS;

  // Equation used:
  //  z1= a1 + a2·x + a3·y + a4·x² + a5·x·y + a6·y² + a7·x³ + a8·x²·y + a9·x·y² + a10·y³
  const z1 =
    a1 +
    a2 * x +
    a3 * y +
    a4 * pow(x, 2) +
    a5 * x * y +
    a6 * pow(y, 2) +
    a7 * pow(x, 3) +
    a8 * pow(x, 2) * y +
    a9 * x * pow(y, 2) +
    a10 * pow(y, 3);

  return z1 < 0 ? 0 : z1;
};

// Opeartion to calculate the amount of lime to prescribe to a field to raise reaction numbers with 0.1
export const calculateLimeAmountToRaiseReactionNumberByFractionMatrix = (
  humusValues: Array<Array<number>>,
  clayValues: Array<Array<number>>
): matrix => {
  const humusMatrix = matrix(humusValues);
  const clayMatrix = matrix(clayValues);

  const z2 = map(humusMatrix, (value, index) =>
    calculateLimeAmountToRaiseReactionNumberByFraction(value, clayMatrix.get(index))
  );

  return z2;
};

// Function to calculate the amount of lime to prescribe to a field to raise a single reaction number with 0.1.
export const calculateLimeAmountToRaiseReactionNumberByFraction = (
  humusValue: number | null,
  clayValue: number | null
): number => {
  if (humusValue === null && clayValue === null) {
    return null;
  }

  const x = humusValue !== null ? humusValue : 0;
  const y = clayValue !== null ? clayValue : 0;

  const { a1, a2, a3, a4, a5, a6, a7, a8, a9, a10 } = LIME_AMOUNT_TO_RAISE_RT_CONSTS;

  // Equation used:
  // z2= a1 + a2·x + a3·y + a4·x² + a5·x·y + a6·y² + a7·x³ + a8·x²·y + a9·x·y² + a10·y³
  const z2 =
    a1 +
    a2 * x +
    a3 * y +
    a4 * pow(x, 2) +
    a5 * x * y +
    a6 * pow(y, 2) +
    a7 * pow(x, 3) +
    a8 * pow(x, 2) * y +
    a9 * x * pow(y, 2) +
    a10 * pow(y, 3);

  return z2 < 0 ? 0 : z2;
};

// Operation to calculate optimal reaction numbers for a given field by taking crop tolerance into account.
export const calculateOptimalReactionNumberWithToleranceMatrix = (
  optimalReactionNumberMatrix: matrix,
  cropTolerance: number
): matrix => {
  const z1Tag = map(optimalReactionNumberMatrix, (value) =>
    calculateOptimalReactionNumberWithToleranceValue(value, cropTolerance)
  );

  return z1Tag;
};

// Function to calculate optimal reaciton number for a signle pint taking crop tolerance into account
export const calculateOptimalReactionNumberWithToleranceValue = (
  optimalReactionNumber: number | null,
  cropTolerance: number
): number => {
  if (optimalReactionNumber === null) {
    return null;
  }

  // Equation used:
  // z1’ = z1 * Ar
  const withTolerance = optimalReactionNumber * cropTolerance;

  return withTolerance < 0 ? 0 : withTolerance;
};

// Operation to calculate amount of lime to prescribe to a field, without taking lime efficiency into account
export const calculateNonEffectiveLimePrescriptionMatrix = (
  optimalReactionNumberWithToleranceMatrix: matrix,
  amountLimeToRaiseReactionNumberByFrationMatrix: matrix,
  reactionNumberValues: Array<Array<number>>
): matrix => {
  const reactionNumberMatrix = matrix(reactionNumberValues);

  const WTonHaMatrix = map(optimalReactionNumberWithToleranceMatrix, (value, index) =>
    calculateNonEffectiveLimePrescriptionValue(
      value,
      amountLimeToRaiseReactionNumberByFrationMatrix.get(index),
      reactionNumberMatrix.get(index)
    )
  );

  return WTonHaMatrix;
};

// Function to calculate amount of lime to prescribe to single point, without taking lime efficiency into account
export const calculateNonEffectiveLimePrescriptionValue = (
  optimalReactionNumberWithTolerance: number | null,
  amountLimeToRaiseReactionNumberByFrationValue: number | null,
  reactionNumber: number | null
): number => {
  if (
    optimalReactionNumberWithTolerance === null &&
    amountLimeToRaiseReactionNumberByFrationValue === null &&
    reactionNumber === null
  ) {
    return null;
  }

  const rt = reactionNumber !== null ? reactionNumber : 0;
  const z1Tag =
    optimalReactionNumberWithTolerance !== null ? optimalReactionNumberWithTolerance : 0;
  const z2 =
    amountLimeToRaiseReactionNumberByFrationValue !== null
      ? amountLimeToRaiseReactionNumberByFrationValue
      : 0;

  // Equation used:
  // W (ton/hA) = (z1' - Rt)* z2*10
  const WTonHa = (z1Tag - rt) * z2 * 10;

  return WTonHa < 0 ? 0 : WTonHa;
};

// Operation to calculate the actual lime prescription amount taking lime efficiency into account
export const calculateActualLimePrescriptionMatrix = (
  nonEffectiveLimePrescriptionMatrix: matrix,
  limeEfficiency: number,
  maxPrescription: number
): { current: matrix; future: matrix | null } => {
  if (limeEfficiency === 0) {
    return nonEffectiveLimePrescriptionMatrix;
  }

  const currentValues = map(nonEffectiveLimePrescriptionMatrix, (value, index) =>
    calculateActualLimePrescriptionValue(value, limeEfficiency, maxPrescription)
  );

  // calc future matrix. Should be null if all values are null.

  const futureValues = map(nonEffectiveLimePrescriptionMatrix, (value) =>
    calculateFuturePrescriptionValue(value, limeEfficiency, maxPrescription)
  );

  const hasFutureValues = checkForNonValues(futureValues);

  return { current: currentValues, future: hasFutureValues ? futureValues : null };
};

// Function to calculate actual lime prescription amount taking lime efficiency into account
export const calculateActualLimePrescriptionValue = (
  nonEffectiveLimePrescriptionValue: number | null,
  limeEfficiency: number,
  maxPrescription: number
): number => {
  if (nonEffectiveLimePrescriptionValue === null) {
    return null;
  }

  const w2 = nonEffectiveLimePrescriptionValue / limeEfficiency;
  const w2InKg = w2 * 1000; // All equations developed are in (Ton / HA), but requirements have changed to be (kg / ha), hence multiplying by 1000.
  const minTransformed = w2InKg < 1 ? 0 : w2InKg; // making sure values are not negative

  return minTransformed > maxPrescription ? maxPrescription : minTransformed;
};

// Function to calculate a future prescription value based on maximum prescription.
const calculateFuturePrescriptionValue = (
  nonEffectiveLimePrescriptionValue: number | null,
  limeEfficiency: number,
  maxPrescription: number
): number | null => {
  if (nonEffectiveLimePrescriptionValue === null) {
    return null;
  }
  const w2 = nonEffectiveLimePrescriptionValue / limeEfficiency;
  const w2InKg = w2 * 1000;
  const minTransformed = w2InKg < 1 ? 0 : w2InKg;

  return minTransformed > maxPrescription ? minTransformed - maxPrescription : null;
};

// Function to check if a mtrix has any values or not
const checkForNonValues = (values: matrix): boolean => {
  return values._data.some((direction) => {
    return direction.some((element) => element !== null);
  });
};

import { LimePrescriptionStrategy } from './LimePrescriptionStrategy';

export class DefaultLimePrescriptionStrategy extends LimePrescriptionStrategy {
  calculateLimePrescription(
    humusValues,
    clayValues,
    reactionNumbers,
    maxPrescription,
    cropTolerance,
    limeEfficiency
  ) {
    const optimalRT = calculateOptimalReactionNumberMatrix(humusValues, clayValues);
    const amountLimeToRaiseRTByFraction = calculateLimeAmountToRaiseReactionNumberByFractionMatrix(
      humusValues,
      clayValues
    );
    const optimalReactionNumberWithTolerance = calculateOptimalReactionNumberWithToleranceMatrix(
      optimalRT,
      cropTolerance
    );
    const nonEffectiveAmountLime = calculateNonEffectiveLimePrescriptionMatrix(
      optimalReactionNumberWithTolerance,
      amountLimeToRaiseRTByFraction,
      reactionNumbers
    );
    const currentAndFuturePrescriptionAmount = calculateActualLimePrescriptionMatrix(
      nonEffectiveAmountLime,
      limeEfficiency / 100,
      maxPrescription
    );

    return {
      current: currentAndFuturePrescriptionAmount.current._data,
      future: currentAndFuturePrescriptionAmount.future
        ? currentAndFuturePrescriptionAmount.future._data
        : null,
    };
  }
}
