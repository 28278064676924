import { Box, TableCell, TableRow, Tooltip } from '@material-ui/core';
import Styles from './SurveyStatisticsUtils.module.less';

import React from 'react';
import type { LanguageFile } from '../../helpers/LanguageUtils';
import WebAPIUtils from '../../WebAPIUtils';
import { getFieldName } from '../../helpers/StateInterpreters';
import { truncateNumber } from '../../helpers/NumberUtils';

const getTooltip = (step, idx, arr, continuous, decimals = 2, unitString) => {
  if (step.label !== undefined && step.label !== null) {
    return <div style={{ display: 'inline-block' }}>{step.label}</div>;
  }

  let tooltip = '';
  let unit = '';
  if (unitString !== undefined && unitString !== null) {
    unit = unitString;
  }

  if ((step.min === null || step.min === undefined) && !step.max) {
    return null;
  }

  let min = Number(step.min).toFixed(decimals);
  let max = Number(step.max).toFixed(decimals);

  if (step.max === Number.MAX_VALUE) {
    return `[${min.toString().replace('.00', '') + unit}[`;
  }

  if (min === max) {
    return min.toString().replace('.00', '') + unit;
  }

  if (idx === 0) {
    if (continuous) {
      tooltip += `< ${max}${unit}`;
    } else {
      tooltip += `[${min}${unit}`;
      if (max) {
        tooltip += ` - ${max}${unit}[`;
      } else {
        tooltip += `]`;
      }
    }
  } else if (idx === arr.length - 1) {
    if (continuous) {
      tooltip += `${min}${unit} <`;
    } else {
      tooltip += `[${min}${unit} - ${max}${unit}]`;
    }
  } else {
    if (max) {
      tooltip += `[${min}${unit} - ${max}${unit}[`;
    } else {
      tooltip += `[${min}${unit}]`;
    }
  }
  return <div style={{ display: 'inline-block' }}>{tooltip}</div>;
};

export const getSurveyScaleItem = (step, idx, arr, continuous, decimals = 2, unitString) => {
  const stepTooltip = getTooltip(step, idx, arr, continuous, decimals, unitString);
  const body = (
    <Box bgcolor={step.color} color={step.contrast} className={Styles.scale} key={idx}></Box>
  );
  return (
    <Tooltip key={idx} title={stepTooltip} placement={'bottom'} disableHoverListener={!stepTooltip}>
      {body}
    </Tooltip>
  );
};

export const generateStatisticsData = (buckets, data, field, decimals = 2) => {
  const values = [];
  data.forEach((array) => {
    array.forEach((value) => {
      if (value !== null) {
        let bucket = buckets.find(
          (bucket) =>
            (bucket.min === bucket.max && value === bucket.min) ||
            (value >= bucket.min && value < bucket.max)
        );
        if (bucket !== null && bucket !== undefined) {
          values.push(value);
          bucket.count += 1;
        }
      }
    });
  });

  const elements = [];
  buckets.forEach((bucket) => {
    const percentage = bucket.count / values.length;
    elements.push({
      bucket: bucket,
      item: bucket.item,
      area: truncateNumber(field.size * percentage, decimals),
      percentage: truncateNumber(percentage * 100, decimals),
    });
  });

  if (values.length === 0 || elements.length === 0) {
    return null;
  }

  values.sort((a, b) => {
    return a - b;
  });

  const sum = values.reduce((partialSum, a) => partialSum + a, 0);
  return {
    min: truncateNumber(values[0], decimals),
    mean: truncateNumber(sum / values.length, decimals),
    max: truncateNumber(values[values.length - 1], decimals),
    items: elements,
  };
};

export const generateStatisticsDataForClassification = (
  buckets,
  classificationData,
  data,
  field,
  decimals = 2
) => {
  const values = [];
  classificationData.forEach((array, x) => {
    array.forEach((value, y) => {
      if (value !== null) {
        let bucket = buckets.find((bucket) => bucket.values.includes(value));
        if (bucket) {
          bucket.count += 1;
          let val = data[x][y];
          if (val) {
            values.push(val);
          }
        }
      }
    });
  });

  const elements = [];
  buckets.forEach((bucket) => {
    const percentage = bucket.count / values.length;
    elements.push({
      bucket: bucket,
      item: bucket.item,
      area: truncateNumber(field.size * percentage, decimals),
      percentage: truncateNumber(percentage * 100, decimals),
    });
  });

  if (values.length === 0 || elements.length === 0) {
    return null;
  }

  values.sort((a, b) => {
    return a - b;
  });

  const sum = values.reduce((partialSum, a) => partialSum + a, 0);

  return {
    min: truncateNumber(values[0], decimals),
    mean: truncateNumber(sum / values.length, decimals),
    max: truncateNumber(values[values.length - 1], decimals),
    items: elements,
  };
};

export const statisticsOverviewColumns = (LangFile: LanguageFile) => {
  return [
    {
      dataField: 'min',
      text: LangFile.SurveyStatistics.min,
    },
    {
      dataField: 'avg',
      text: LangFile.SurveyStatistics.mean,
    },
    {
      dataField: 'max',
      text: LangFile.SurveyStatistics.max,
    },
  ];
};

export const statisticsOverviewRows = (values) => {
  return [
    <TableRow key={'bodyRow'}>
      {values.map((value, index) => {
        return (
          <TableCell className={Styles.cell} key={index} align="left" padding="none">
            {value}
          </TableCell>
        );
      })}
    </TableRow>,
  ];
};

export const statisticsDataColumns = (LangFile: LanguageFile) => {
  return [
    {
      dataField: 'type',
      text: LangFile.SurveyStatistics.zone,
    },
    {
      dataField: 'area',
      text: 'Ha',
    },
    {
      dataField: 'percentage',
      text: '%',
    },
  ];
};

export const statisticsDataRows = (items) => {
  return items.map((item, index) => {
    return (
      <TableRow key={'bodyRow' + index}>
        <TableCell className={Styles.cell} key={'type' + index} align="left" padding="none">
          {item.item}
        </TableCell>
        <TableCell className={Styles.cell} key={'area' + index} align="left" padding="none">
          {item.area}
        </TableCell>
        <TableCell className={Styles.cell} key={'percentage' + index} align="left" padding="none">
          {item.percentage}
        </TableCell>
      </TableRow>
    );
  });
};

export const fetchSurveyStatisticsTabulated = async (farm, field, survey, layers, LangFile) => {
  let farmId = farm.farmId;
  let fieldId = field.fieldId;

  let promises = layers.map((layer) => {
    return WebAPIUtils.getLayerValues(farmId, fieldId, null, layer, survey);
  });

  let result = await Promise.all(promises);

  let stats = result.map((values) => {
    let pixels = values.flatten().filter((p) => p != null);
    let sum = pixels.reduce((partialSum, a) => partialSum + a, 0);
    let mean = sum / pixels.length;
    return Number(mean.toFixed(2));
  });

  let items = [fieldId, getFieldName(field, LangFile), Number(field.size.toFixed(2)), ...stats];

  return items.join('\t');
};
