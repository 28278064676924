import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getVariableColorScale } from 'js/constants/ColorMaps';
import InlineColorScale from 'js/components/UI-Elements/InlineColorScale';

const VariableColorScale = ({ variableColorScaleObject, tooltip, unit }) => {
  if (variableColorScaleObject && unit.includes('t/ha')) {
    variableColorScaleObject.sort((a, b) => a.min - b.min);
  }
  return (
    <>
      {variableColorScaleObject && (
        <InlineColorScale
          scale={getVariableColorScale(variableColorScaleObject)}
          tooltip={tooltip}
          unit={unit}
        />
      )}
    </>
  );
};

VariableColorScale.propTypes = {
  variableColorScaleObject: PropTypes.array,
  tooltip: PropTypes.string,
  unit: PropTypes.string,
};

VariableColorScale.defaultProps = {};

export default VariableColorScale;
