// @flow

import React, { memo, useCallback, useEffect, useState, useRef } from 'react';

import { SURVEY_LAYERS } from '../../constants/SurveyLayers';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import PrescriptionEditor from 'js/components/Prescription/PrescriptionEditor';
import { useFarm } from '../../context/AccountContext';
import {
  calculateClassificationIntervalAreas,
  calculateClassificationIntervals,
  calculateIntervalAreas,
  calculateIntervals,
  calculateOverridesArea,
  initializeOverrides,
  calculateLimeJobIntervals,
  adjustValuesBetweeen,
} from './PrescriptionUtils';
import PrescriptionSettingsDialog from 'js/components/Prescription/Dialogs/PrescriptionSettingsDialog/PrescriptionSettingsDialog';
import PrescriptionAutoAdjustDialog from 'js/components/Prescription/Dialogs/PrescriptionAutoAdjustDialog/PrescriptionAutoAdjustDialog';
import { useSurveyContext, useSurveyLayerViewCapabilities } from '../../context/SurveyContext';
import { PrescriptionJob, MetaTypes, Interval } from './PrescriptionJob';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import { useLangFile } from '../../context/LanguageContext';
import { connect } from 'react-redux';
import { getReferenceValues, getLimeJobValues } from '../../reducers/SurveyReducer';
import ViewModeConstants from '../../constants/ViewModeConstants';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { resetFutureJob, setCurrentJob, setFutureJob } from '../../reducers/PrescriptionReducer';
import MultipleActionsDialog from '../Prescription/Dialogs/MultipleActionsDialog.jsx';
import { LOWEST_INTERVAL_HIGH } from './PrescriptionJob';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DefaultLimePrescriptionStrategy } from './LimeJob/LimeJobStrategies/DefaultLimePrescriptionStrategy';
import { AlternativeLimePrescriptionStrategy } from './LimeJob/LimeJobStrategies/AlternativeLimePrescriptionStrategy';
import { usePrescriptionEditorContext } from './PrescriptionEditorContext';

const validJob = (job: PrescriptionJob) => {
  const invalidIntervals = job.intervals.filter((interval) => {
    return (
      Number.isNaN(interval.min) ||
      Number.isNaN(interval.max) ||
      !Number.isFinite(interval.min) ||
      !Number.isFinite(interval.max)
    );
  });

  return invalidIntervals.length <= 0;
};

const mapStateToProps = (store) => ({
  selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
  selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,
});

const PrescriptionEditorContainer = ({
  dispatch,
  onExit,
  onExitSafe,
  onDownloadJob,
  selectedField,
  selectedSurveyReferenceValues,
  selectedSurveyClassificationValues,
  disableSettingsDialog,
  onSaveJob,
  onDeleteFutureJob,
  isResumingJob,
  updatePrescriptionJob,
  onMergePrescriptionJobs,
}) => {
  const farm = useFarm();
  const LangFile = useLangFile();
  const { addAction } = useActionSnackbarContext();
  const { surveys, selectedSurvey } = useSurveyContext();
  const { prescriptionJob, setPrescriptionJob } = usePrescriptionJob();
  const layerCapabilities = useSurveyLayerViewCapabilities(
    prescriptionJob.layer,
    ViewModeConstants.PRESCRIPTION
  );

  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showAutoAdjustDialog, setShowAutoAdjustDialog] = useState(false);
  const [classificationsEnabled, setClassificationsEnabled] = useState(false);
  const [classificationReset, setClassificationReset] = useState(false);
  const [showIntervalAdjustDialog, setShowIntervalAdjustDialog] = useState(false);
  const [initialLayerType, setInitialLayerType] = useState(prescriptionJob.layer);
  const defaultPrescriptionJobRef = useRef(prescriptionJob);
  const [showLoading, setShowLoading] = useState(false);

  const { mapsState, updateMapState, selectedMap, datesWithImages, setMapPrescriptionBasedOn } =
    usePrescriptionEditorContext();

  // When a new job is started or settings are modified.
  useEffect(() => {
    if (!prescriptionJob || prescriptionJob.maxPrescription) {
      return;
    }
    setShowSettingsDialog(true);
  }, [prescriptionJob]);

  useEffect(() => {
    const isDefaultAndPrescriptionSame =
      prescriptionJob.metaType === defaultPrescriptionJobRef.current.metaType &&
      prescriptionJob.maxPrescription === defaultPrescriptionJobRef.current.maxPrescription &&
      prescriptionJob.limeInfo?.efficiency ===
        defaultPrescriptionJobRef.current.limeInfo?.efficiency &&
      prescriptionJob.limeInfo?.tolerance === defaultPrescriptionJobRef.current.limeInfo?.tolerance;

    const hasValidLayerOrValues =
      !prescriptionJob.layer || !prescriptionJob.maxPrescription || prescriptionJob.values;

    if (showSettingsDialog || (isDefaultAndPrescriptionSame && hasValidLayerOrValues)) {
      return;
    }

    setShowLoading(true);
    const metaTypeToValuesFetchAction = {
      [MetaTypes.FERTILIZING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.SEEDING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.LIME]: () =>
        fetchDataForLimeJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.SPRAYING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.SPOT_SPRAYING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.LEGACY]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
    };

    const dataFetchAction = metaTypeToValuesFetchAction[prescriptionJob.metaType];

    dataFetchAction();
  }, [prescriptionJob, farm, selectedSurvey, showSettingsDialog]);

  const defaultDataFecthForJob = (farmId, fieldId, date, layer, survey, jobId) => {
    dispatch(getReferenceValues(farmId, fieldId, date, layer, survey, jobId));
  };

  const fetchDataForLimeJob = (
    farmId: number,
    fieldId: number,
    date: Date,
    survey,
    jobId: number
  ) => {
    dispatch(getLimeJobValues(farmId, fieldId, date, survey, jobId));
  };

  useEffect(() => {
    const isDefaultAndPrescriptionSame =
      prescriptionJob.metaType === defaultPrescriptionJobRef.current.metaType &&
      prescriptionJob.maxPrescription === defaultPrescriptionJobRef.current.maxPrescription &&
      prescriptionJob.limeInfo?.efficiency ===
        defaultPrescriptionJobRef.current.limeInfo?.efficiency &&
      prescriptionJob.limeInfo?.tolerance === defaultPrescriptionJobRef.current.limeInfo?.tolerance;

    const hasValidValuesAndIntervals = prescriptionJob.values && prescriptionJob.intervals;

    const isSurveyReferenceMissing = !selectedSurveyReferenceValues[prescriptionJob.layer];

    const isMetaTypeEmpty = prescriptionJob.metaType === '';

    if (
      showSettingsDialog ||
      (isDefaultAndPrescriptionSame && hasValidValuesAndIntervals) ||
      (isSurveyReferenceMissing && isDefaultAndPrescriptionSame) ||
      isMetaTypeEmpty
    ) {
      return;
    }
    // FOR NOW COUNTRY IS HARDCODED TO Default
    const country = 'Default';
    let limePrescriptionStrategy;
    if (country === 'Default') {
      limePrescriptionStrategy = new DefaultLimePrescriptionStrategy();
    } else if (country === 'Alternative') {
      limePrescriptionStrategy = new AlternativeLimePrescriptionStrategy();
    } else {
      throw new Error('Unsupported country strategy');
    }

    // Maybe we want to handle all special cases, but for now this will suffice
    const currentAndFutureValues =
      prescriptionJob.metaType !== MetaTypes.LIME
        ? { current: selectedSurveyReferenceValues[prescriptionJob.layer], future: null }
        : limePrescriptionStrategy.calculateLimePrescription(
            selectedSurveyReferenceValues[SURVEY_LAYERS.HUMUS],
            selectedSurveyReferenceValues[SURVEY_LAYERS.CLAY],
            selectedSurveyReferenceValues[SURVEY_LAYERS.RT],
            prescriptionJob.maxPrescription / prescriptionJob.fieldSize,
            prescriptionJob.limeInfo.tolerance,
            prescriptionJob.limeInfo.efficiency
          );

    const currentValues = currentAndFutureValues.current;
    const futureValues = currentAndFutureValues.future;

    if (currentValues == null) {
      // invalid choice
      setPrescriptionJob({ source: null, layer: null });
      return;
    }

    // Here we manually calculate the job from the ground instead of having the reducer recalculate partially
    const jobIndex = prescriptionJob.vrmSet ? prescriptionJob.vrmSet.jobIndex : 0;
    const jobWithValues: PrescriptionJob = {
      ...prescriptionJob,
      values: currentValues,
      intervals:
        prescriptionJob.metaType !== defaultPrescriptionJobRef.current.metaType ||
        prescriptionJob.maxPrescription !== defaultPrescriptionJobRef.current.maxPrescription
          ? undefined
          : prescriptionJob.intervals,
    };
    const commonIntervals = () => calculateIntervals(jobWithValues);

    const metaTypeToIntervalCalculation = {
      [MetaTypes.FERTILIZING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.SEEDING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.LIME]: {
        getIntervals: () => calculateLimeJobIntervals(jobWithValues.values),
      },
      [MetaTypes.SPRAYING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.SPOT_SPRAYING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.LEGACY]: {
        getIntervals: () => commonIntervals(),
      },
    };

    const jobWithIntervalsAndOverrides: PrescriptionJob = {
      ...jobWithValues,
      overrides: !jobWithValues.overrides
        ? initializeOverrides(jobWithValues)
        : jobWithValues.overrides,
      intervals: !jobWithValues.intervals
        ? metaTypeToIntervalCalculation[prescriptionJob.metaType].getIntervals()
        : jobWithValues.intervals,
    };

    const jobWithOverrideAreas = {
      ...jobWithIntervalsAndOverrides,
      overrideAreas: calculateOverridesArea(jobWithIntervalsAndOverrides),
    };

    const newJob: PrescriptionJob = calculateIntervalAreas(jobWithOverrideAreas);
    if (newJob.intervals) {
      setShowLoading(false);
    }

    // Show adjusting dialog if lime prescription for lowest interval is set
    if (
      prescriptionJob.metaType === MetaTypes.LIME &&
      newJob.intervals[0].prescription > 0 &&
      !isResumingJob
    ) {
      setShowIntervalAdjustDialog(true);
    }

    if (!validJob(newJob)) {
      // this layer cannot produce a valid prescription map
      addAction(
        new Action(
          'invalid-layer',
          LangFile.PrescriptionEditorContainer.invalidValues,
          'error',
          'filled'
        )
      );
      setPrescriptionJob({ layer: null });
    }

    if (!jobIndex > 0) {
      defaultPrescriptionJobRef.current = newJob;
      setPrescriptionJob(newJob, false);
    }

    // Split out into functions and set currentJob as FutureJob if jobIndex is higher
    if (futureValues === null || (isResumingJob && jobIndex <= 0)) {
      onDeleteFutureJob();
      return;
    }
    const vrmSet = prescriptionJob.vrmSet;
    const futureJobVrmSet = vrmSet != null ? vrmSet : { jobIndex: 1 };
    const futureJobWithValues: PrescriptionJob = {
      ...prescriptionJob,
      jobName: isResumingJob
        ? prescriptionJob.jobName
        : prescriptionJob.jobName + ` - ${LangFile.PrescriptionEditorContainer.future}`,
      values: futureValues,
      date: prescriptionJob.date
        ? new Date(
            prescriptionJob.date.getFullYear() + 1,
            prescriptionJob.date.getMonth(),
            prescriptionJob.date.getDate()
          )
        : new Date(),
      vrmSet: futureJobVrmSet,
    };

    const futureJobWithIntervalsAndOverrides: PrescriptionJob = {
      ...futureJobWithValues,
      overrides: !futureJobWithValues.overrides
        ? initializeOverrides(futureJobWithValues)
        : futureJobWithValues.overrides,
      intervals: isResumingJob
        ? futureJobWithValues.intervals
        : calculateLimeJobIntervals(futureJobWithValues.values),
    };

    const futureJobWithOverrideAreas: PrescriptionJob = {
      ...futureJobWithIntervalsAndOverrides,
      overrideAreas: calculateOverridesArea(futureJobWithIntervalsAndOverrides),
      originalValues: currentValues,
    };

    const newFutureJob: PrescriptionJob = calculateIntervalAreas(futureJobWithOverrideAreas);
    if (jobIndex > 0) {
      setFutureJobAsCurrentJob(newFutureJob);
      return;
    }

    setFutureJobAsFutureJob(newFutureJob);
  }, [selectedSurveyReferenceValues]);

  const setFutureJobAsFutureJob = (futureJob: PrescriptionJob) => {
    dispatch(setFutureJob(futureJob));
  };

  const setFutureJobAsCurrentJob = (futureJob: PrescriptionJob) => {
    setPrescriptionJob(futureJob, false);
  };

  useEffect(() => {
    let map = Object.values(mapsState).find((map) => map.identifier === selectedMap);
    if (
      classificationReset &&
      prescriptionJob.values &&
      layerCapabilities &&
      layerCapabilities.enableCustomClassification &&
      selectedSurveyClassificationValues[map.sourceLayer]
    ) {
      if (map.classificationsEnabled) {
        let newJob = {
          ...prescriptionJob,
          intervals: [],
          classifications: selectedSurveyClassificationValues[map.sourceLayer],
          layer: map.sourceLayer,
          layerType: 'FI_DEMAND',
          values: map.referenceValues[map.sourceLayer],
        };
        newJob.intervals = calculateClassificationIntervals(newJob);
        newJob = calculateClassificationIntervalAreas(newJob);
        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
      } else {
        let newJob = {
          ...prescriptionJob,
          intervals: [],
          classifications: undefined,
          layerType: undefined,
        };
        newJob.intervals = calculateIntervals(newJob);
        newJob = calculateIntervalAreas(newJob);
        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
      }
      setClassificationReset(false);
    }
  }, [classificationReset, selectedSurveyClassificationValues, layerCapabilities]);

  const onAdjustLowInterval = useCallback((amount: number) => {
    const intervalToAdjust = prescriptionJob.intervals[0];
    const adjustedInterval: Interval = { ...intervalToAdjust, prescription: amount };
    const intervals: Interval[] = [
      adjustedInterval,
      ...prescriptionJob.intervals.slice(1, prescriptionJob.intervals.length),
    ];

    const newValues = adjustValuesBetweeen(
      prescriptionJob.values,
      amount,
      intervalToAdjust.min,
      intervalToAdjust.max
    );

    const jobWithAdjustedLowInterval: PrescriptionJob = {
      ...prescriptionJob,
      intervals,
      values: newValues,
    };

    dispatch(setCurrentJob(jobWithAdjustedLowInterval, true));

    setTimeout(() => {
      setShowIntervalAdjustDialog(false);
    }, 100);
  });

  const interval =
    prescriptionJob.intervals && prescriptionJob.intervals.length > 0
      ? prescriptionJob.intervals[0]
      : null;

  const enableNdvi =
    prescriptionJob.assets != null &&
    prescriptionJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] !== null &&
    prescriptionJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] !== undefined;
  const enableSurveys = Boolean(
    surveys.find((survey) => survey.fieldId === prescriptionJob.fieldId)
  );

  const handleUpdatePrescriptionJob = useCallback((changes, recalculate) => {
    const layer = changes.layer;
    if (layer) {
      setInitialLayerType(layer);
    }
    updatePrescriptionJob(changes, recalculate);
  });
  return (
    <Box
      style={{ overflow: 'hidden' }}
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'center'}>
      {showLoading && !showSettingsDialog && (
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}>
          <CircularProgress size={60} thickness={5} />
        </Box>
      )}
      <PrescriptionEditor
        onDownloadJob={onDownloadJob}
        onSetShowSettingsDialog={setShowSettingsDialog}
        onSetShowAutoAdjustDialog={setShowAutoAdjustDialog}
        enableSurveys={enableSurveys}
        selectedField={selectedField}
        enableNdvi={enableNdvi}
        onExitSafe={onExitSafe}
        setClassificationsReset={setClassificationReset}
        onDeleteFutureJob={onDeleteFutureJob}
        onSaveJob={onSaveJob}
        updatePrescriptionJob={handleUpdatePrescriptionJob}
        onMergePrescriptionJobs={onMergePrescriptionJobs}
        showSettingsDialog={showSettingsDialog}
      />
      <PrescriptionSettingsDialog
        open={showSettingsDialog}
        onExit={onExit}
        exitEnabled={!(prescriptionJob && prescriptionJob.intervals)}
        onSetOpen={setShowSettingsDialog}
        disableDialog={disableSettingsDialog}
        initialLayerType={initialLayerType}
      />
      <PrescriptionAutoAdjustDialog
        open={showAutoAdjustDialog}
        onSetOpen={setShowAutoAdjustDialog}
      />
      <MultipleActionsDialog
        open={showIntervalAdjustDialog}
        title={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.title}
        message={`${interval?.prescription.toFixed(2)} ${LangFile.PrescriptionEditorContainer.limeAdjustPrescription.message}`}
        cancelText={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.cancel}
        onCancel={() => setShowIntervalAdjustDialog(false)}
        primaryText={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.thousand}
        onPrimary={() => onAdjustLowInterval(LOWEST_INTERVAL_HIGH)}
        secondaryText={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.zero}
        onSecondary={() => onAdjustLowInterval(0)}
      />
    </Box>
  );
};

PrescriptionEditorContainer.propTypes = {
  selectedField: PropTypes.object,
  onExit: PropTypes.func,
  onExitSafe: PropTypes.func,
  onDownloadJob: PropTypes.func,
  selectedSurveyReferenceValues: PropTypes.object,
  selectedSurveyClassificationValues: PropTypes.object,
  disableSettingsDialog: PropTypes.bool,
  showCurrentJob: PropTypes.bool,
  onSaveJob: PropTypes.func,
  onDeleteFutureJob: PropTypes.func,
  isresumingJob: PropTypes.bool,
  updatePrescriptionJob: PropTypes.func,
  onMergePrescriptionJobs: PropTypes.func,
};

export default memo(connect(mapStateToProps)(PrescriptionEditorContainer));
