// @flow
import { matrix, map, pow } from 'mathjs';

export const mergePrescriptionJobsValues = (
  primaryValues: (number | null)[][],
  secondaryValues: (number | null)[][]
): number[][] => {
  const primaryMatrix = matrix(primaryValues);
  const secondaryMatrix = matrix(secondaryValues);
  const mergedValues = map(primaryMatrix, (value, index) =>
    mergeValues(value, secondaryMatrix.get(index), index)
  );
  return mergedValues._data;
};

const mergeValues = (
  primaryValue: number | null,
  secondaryValue: number | null,
  index
): number | null => {
  if (primaryValue == null && secondaryValue == null) {
    return null;
  }

  if (primaryValue == null) {
    return secondaryValue;
  }

  if (secondaryValue == null) {
    return primaryValue;
  }

  return primaryValue + secondaryValue;
};
