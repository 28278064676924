import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { useLangFile } from 'js/context/LanguageContext';
import CircularProgress from '@material-ui/core/CircularProgress';

const PrescriptionUpdateJobDialog = ({
  open,
  savingJob,
  savingError,
  onCancel,
  onSaveAsNew,
  info,
}) => {
  const LangFile = useLangFile();

  return (
    <Fragment>
      <Dialog open={Boolean(open)} fullWidth maxWidth={'md'}>
        <DialogTitle>
          {savingJob && LangFile.PrescriptionSaveDialog.saving}
          {!savingJob && LangFile.PrescriptionUpdateJobDialog.title}
        </DialogTitle>

        <DialogContent style={{ overflow: 'hidden' }}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {savingJob ? (
              <CircularProgress size={80} thickness={5} />
            ) : (
              <Fragment>
                <Box mb={1}>{info}</Box>
              </Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {!savingJob && (
            <Fragment>
              <Button onClick={onCancel} color={'default'} variant={'outlined'}>
                {LangFile.PrescriptionUpdateJobDialog.cancel}
              </Button>
              <Fragment>
                <Button onClick={onSaveAsNew} color={'primary'} variant={'contained'}>
                  {LangFile.PrescriptionUpdateJobDialog.saveAsNewJob}
                </Button>
              </Fragment>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(PrescriptionUpdateJobDialog);

PrescriptionUpdateJobDialog.propTypes = {
  open: PropTypes.bool,
  savingJob: PropTypes.bool,
  savingError: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveAsNew: PropTypes.func.isRequired,
};
